<template>
  <b-button variant="flat-primary" class="datasource-select-container scatter-select-container" ref="button" @click="clButton">
    <div class="datasource-pointer" v-ripple.400="'rgba(57, 123, 255, 0.15)'">
      <b-media no-body>
        <b-media-aside>
          <b-avatar rounded size="42" variant="light-primary">
            <feather-icon size="18" icon="DatabaseIcon" style="color: #397bff" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="datasource-title text-uppercase">{{ $t('datasource') }}</h6>
          <small class="datasource-description">
            <span>{{ pairSelected.length }}</span>
            <span>{{ $t('item_selected') }}</span>
          </small>
        </b-media-body>
      </b-media>
      <b-badge pill variant="light-primary">
        <feather-icon size="18" icon="ChevronDownIcon" />
      </b-badge>
    </div>
    <div class="datasource-select bg-light">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 202px">
              <div class="d-flex justify-content-between align-items-center" style="padding-right: 5px">
                <span style="line-height: 22px">{{ $t('datasource') }}</span>
              </div>
            </th>
            <th style="width: 600px">
              <div class="d-flex justify-content-between align-items-center" style="padding-right: 5px">
                <span style="line-height: 22px">{{ $t('item') }}</span>
                <div :title="$t('add')" style="min-width: 18px; cursor: pointer" @click="addPair">
                  <feather-icon size="18" icon="PlusSquareIcon" />
                </div>
              </div>
            </th>
            <!-- <th style="width: 200px;">
              <div class="d-flex justify-content-between align-items-center" style="padding-right: 5px;">
                <span style="line-height: 22px;">Location</span>
              </div>
            </th> -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="custom-scrollbar">
                <div class="datasource-option" v-for="datasource in datasources" :key="datasource.id" @click="toggleDatasource(datasource.id)">
                  <div class="datasource-content">
                    <div class="datasource-name">
                      <feather-icon icon="RadioIcon" title="Live mode" v-if="datasource.live != null && datasource.type != 'netcdf'" @click.stop="changeDatasourceLiveMode(datasource)" :class="[datasource.live ? 'text-danger' : 'text-secondary']" />
                      {{ datasource.name }}
                    </div>
                    <small class="count-label" v-if="$i18n.locale === 'en'">
                      <span>Select</span>
                      <b-badge :variant="itemVariant(datasource.id)">{{ itemCount(datasource.id) }}/{{ itemTotal(datasource.id) }}</b-badge>
                      <span>Pairs</span>
                    </small>
                    <small class="count-label" v-else>
                      <b-badge :variant="itemVariant(datasource.id)">{{ itemCount(datasource.id) }}/{{ itemTotal(datasource.id) }}</b-badge>
                      <span>{{ $t('select_pairs') }}</span>
                    </small>
                  </div>
                  <b-form-checkbox :checked="datasourceSelected.some((d) => d === datasource.id) ? ['A'] : []" value="A" disabled></b-form-checkbox>
                </div>
              </div>
            </td>
            <td>
              <!-- Scatter plotly có chứa thêm option -->
              <div v-if="optionSelect" class="custom-scrollbar" style="overflow-y: scroll">
                <div v-for="(pair, index) in pairSelected" :key="pair.id">
                  <div class="scatter-option">
                    <div class="scatter-option-select">
                      <vue-select :options="options" :reduce="(data) => data.value" class="select-size-sm" :appendToBody="true" placeholder="X" :value="pair.x" @input="selectX(pair.id, $event)">
                        <template #no-options>{{ $t('sorry_no_matching_options') }}</template>
                      </vue-select>
                    </div>
                    <div class="scatter-option-select">
                      <vue-select :options="options" :reduce="(data) => data.value" class="select-size-sm" :appendToBody="true" placeholder="Y" :value="pair.y" @input="selectY(pair.id, $event)">
                        <template #no-options>{{ $t('sorry_no_matching_options') }}</template>
                      </vue-select>
                    </div>
                    <div class="scatter-option-select">
                      <vue-select :options="getListOptionSize(pair.x, pair.y)" :reduce="(data) => data.value" class="select-size-sm" :appendToBody="true" :placeholder="$t('size')" :value="valueSize(pair.x, pair.y, pair.value, pair.id)" @input="selectValue(pair.id, $event)">
                        <template #no-options>{{ $t('sorry_no_matching_options') }}</template>
                      </vue-select>
                    </div>
                    <feather-icon size="18" icon="ChevronRightIcon" style="min-width: 18px; cursor: pointer" class="show-hide-option" @click="showHideOption(index)" />
                    <div class="text-danger" style="min-width: 18px; cursor: pointer"  @click="deletePair(pair.id)" :title="$t('delete')">
                      <feather-icon size="18" icon="Trash2Icon" />
                    </div>
                  </div>
                  <div :id="`box-option-${index}`" class="scatter-option radio-option-scatter">
                    <RadioGroup :moveCount="`scatter-select-type-${index}-${moveCountRadioGroup}`" :name="`scatter-select-type-${index}`" :label="`Type-${index}`" :hiddenLable="true" :options="listTypeScatter" @input="selectType(pair.id, $event)" :value="getTypeSelect(pair.typeSelect)" ref="radioContainer" />
                    <div class="ml-2 shape-pair" :title="$t('shape')">
                      <div class="shape-pair-selected" :class="`shape-pair-selected-${index}`" ref="shapePairChartSelected2" @click="clickShowChartShape(index)"></div>
                    </div>
                    <div class="ml-2">
                      <label :for="`checkbox-fill-${index}`">{{ $t('na_fill_zezo') }}</label>
                      <b-form-checkbox @change="selectModeFill(pair.id, index)" :id="`checkbox-fill-${index}`" switch> </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Scatter Uplot Không Có Option -->
              <div v-if="!optionSelect" class="custom-scrollbar" style="overflow-y: scroll">
                <div class="scatter-option" v-for="pair in pairSelected" :key="pair.id">
                  <div class="scatter-option-select">
                    <vue-select :options="options" :reduce="(data) => data.value" class="select-size-sm" :appendToBody="true" placeholder="X" :value="pair.x" @input="selectX(pair.id, $event)">
                      <template #no-options>{{ $t('sorry_no_matching_options') }}</template>
                    </vue-select>
                  </div>
                  <div class="scatter-option-select">
                    <vue-select :options="options" :reduce="(data) => data.value" class="select-size-sm" :appendToBody="true" placeholder="Y" :value="pair.y" @input="selectY(pair.id, $event)">
                      <template #no-options>{{ $t('sorry_no_matching_options') }}</template>
                    </vue-select>
                  </div>
                  <div class="scatter-option-select">
                    <vue-select :options="getListOptionSize(pair.x, pair.y)" :reduce="(data) => data.value" class="select-size-sm" :appendToBody="true" :placeholder="$t('size')" :value="valueSize(pair.x, pair.y, pair.value, pair.id)" @input="selectValue(pair.id, $event)">
                      <template #no-options>{{ $t('sorry_no_matching_options') }}</template>
                    </vue-select>
                  </div>
                  <div class="text-danger" style="min-width: 18px; cursor: pointer" @click="deletePair(pair.id)" :title="$t('delete')">
                    <feather-icon size="18" icon="Trash2Icon" />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="shape-pair-chart card" ref="shapePairChart" v-show="showChartShape"></div>
    </div>
  </b-button>
</template>

<script>
const _ = require('lodash');
import Ripple from 'vue-ripple-directive';
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue';
import RadioGroup from '../RadioGroup.vue';
import Plotly from 'plotly.js-dist';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_COLOR_HIDEN_SYMBOL = '#82868b';
const DEFAULT_OPACITY_HIDEN_SYMBOL = 0.5;
const DEFAULT_COLOR_SHOW_SYMBOL = 'rgb(17, 157, 255)';
const LAYOUT = {
  margin: { l: 0, r: 0, b: 0, t: 0 },
  width: 300,
  height: 800,
  xaxis: {
    visible: false,
    showgrid: false,
    zeroline: false,
    fixedrange: true,
  },
  yaxis: {
    visible: false,
    showgrid: false,
    zeroline: false,
    autorange: 'reversed',
    fixedrange: true,
  },
  showlegend: false,
  plot_bgcolor: 'transparent',
  paper_bgcolor: 'transparent',
};
const CONFIG = {
  displayModeBar: false,
};
export default {
  props: ['value', 'optionSelect', 'nameComponent'],
  components: { AppCollapse, AppCollapseItem, RadioGroup },
  directives: { Ripple },
  mounted() {
    var self = this;
    this.autoPickDatasource();
    this.clickOutsideDiv(this.$refs.shapePairChart);
    this.getSymbols();
    this.getDataSymbols(this.symbols);
    this.symbolChart(this.$refs.shapePairChart, this.dataSymbols);
    this.$refs.shapePairChart.on('plotly_click', function (data) {
      let posY = data.points[0].x;
      let posX = data.points[0].y == 0 ? 0 : data.points[0].y / 10;
      let id = posX * self.rowLenSymbols + posY;
      let idReset = self.symbolSelected[0];
      if (self.dataSymbols[id].opacity !== 1) {
        self.dataSymbols[idReset].opacity = DEFAULT_OPACITY_HIDEN_SYMBOL;
        self.dataSymbols[idReset].marker.color = DEFAULT_COLOR_HIDEN_SYMBOL;
        self.dataSymbols[id].opacity = 1;
        self.dataSymbols[id].marker.color = DEFAULT_COLOR_SHOW_SYMBOL;
        self.symbolSelected = [id];
        self.pairSelected[self.pairIndexSelected].symbol = id;
        self.getDataSymbolsSelected(self.symbolSelected);
        self.symbolSelectedChart(document.querySelector(`.shape-pair-selected-${self.pairIndexSelected}`), self.dataSymbolsSelected);

        // update chart
        let oldPair = self.pairSelected[self.pairIndexSelected];
        self.pairSelected.splice(self.pairIndexSelected, 1, { ...oldPair, symbol: self.symbols[id] });
      }
      LAYOUT.width = 300;
      LAYOUT.height = 800;
      Plotly.redraw(self.$refs.shapePairChart, self.dataSymbols, LAYOUT, CONFIG);
    });   
  },
  data() {
    return {
      test: false,
      datasourceSelected: [],
      optionSize: [],
      showChartShape: false,
      symbols: [],
      symbolSelected: [],
      rowLenSymbols: 8,
      dataSymbols: [],
      dataSymbolsSelected: [],
      pairIndexSelected: null,
      moveCountRadioGroup: 0,
    };
  },
  computed: {
    markerSymbolDefault() {
      return this.$store.state.settings.markerSymbol;
    },
    pairSelected: {
      get() {
        return this.value;
      },
      set(pairSelected) {
        this.$emit('input', pairSelected);
      },
    },
    listTypeScatter() {
      return [
        { text: this.$i18n.t('generate_all'), value: 'generateAll' },
        { text: this.$i18n.t('match_by_locationID'), value: 'matchByLocationID' },
      ];
    },
    datasources() {
      return this.$store.state.datasource.datasources.filter((d) => d.type === 'timeseries');
    },
    pairs() {
      let pairs = [];
      this.datasourceSelected.forEach((d) => {
        let datasource = this.datasources.find((s) => s.id === d);
        if (!datasource) return;
        pairs.push(...Object.keys(this.$db[datasource.id].items).map((key) => `${datasource.id} : ${key}`));
      });
      return pairs;
    },
    options() {
      let options = this.pairs.map((p) => {
        let key = p.split(' : ')[1];
        return { value: p, label: key };
      });
      return options;
    },
    optionsValue(valueX, valueY) {
      let array = [];

      let idValueX = null;
      if (valueX) {
        idValueX = valueX.split(' : ')[0];
      }
      let idValueY = null;
      if (valueY) {
        idValueY = valueY.split(' : ')[0];
      }
      let options = this.pairs.map((p) => {
        if (idValueX) {
          if (p.split(' : ')[0] === idValueX) {
            let key = p.split(' : ')[1];
            let flag = false;
            for (let i = 0; i < array.length; i++) {
              if (array[i].label === key) flag = true;
            }
            if (!flag) array.push({ value: p, label: key });
          }
        }
        if (idValueY) {
          if (p.split(' : ')[0] === idValueY) {
            let key = p.split(' : ')[1];

            let flag = false;
            for (let i = 0; i < array.length; i++) {
              if (array[i].label === key) flag = true;
            }
            if (!flag) array.push({ value: p, label: key });
          }
        }
      });
      return array;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    locale() {
      this.changeTitle()
    },
    datasources(datasources) {
      this.datasourceSelected = this.datasourceSelected.filter((d) => datasources.some((s) => s.id === d));
    },
    pairs(pairs) {
      let pairSelected = [];
      this.pairSelected.forEach((p) => {
        pairSelected.push({ ...p, x: pairs.includes(p.x) ? p.x : null, y: pairs.includes(p.y) ? p.y : null, value: pairs.includes(p.value) ? p.value : null });
      });
      this.pairSelected = pairSelected;
    },
    pairSelected() {
      this.autoPickDatasource();
    },
  },
  methods: {
    changeTitle(){
      let buttonClear = document.querySelectorAll('.vs__clear');
      for (let i = 0; i < buttonClear.length; i++) {
        if (this.locale !== 'en') buttonClear[i].title = 'クリア';
        else buttonClear[i].title = 'Clear Selected';
      }
    },
    showHideOption(index) {
      let heightEle = document.querySelector('#box-option-' + index).offsetHeight;
      if (heightEle === 0) {
        if (document.querySelectorAll('.show-hide-option')[index]) document.querySelectorAll('.show-hide-option')[index].classList.add('open');
        if (document.querySelector('#box-option-' + index)) document.querySelector('#box-option-' + index).classList.add('show-option');
        this.$nextTick(() => {
          this.$refs.radioContainer[index].moveIndicator();
          let id = this.symbols.indexOf(this.pairSelected[index].symbol);
          id = id >= -1 ? id : 0;
          this.getDataSymbolsSelected([id]);
          this.symbolSelectedChart(document.querySelector(`.shape-pair-selected-${index}`), this.dataSymbolsSelected);
        });
      } else {
        if (document.querySelectorAll('.show-hide-option')[index]) document.querySelectorAll('.show-hide-option')[index].classList.remove('open');
        if (document.querySelector('#box-option-' + index)) document.querySelector('#box-option-' + index).classList.remove('show-option');
      }
      this.moveCountRadioGroup++;
    },
    openCollapse(index) {
      this.$nextTick(() => {
        this.$refs.radioContainer[index].moveIndicator();
      });
    },
    getTypeSelect(value) {
      if (!value) return 'generateAll';
      else return value;
    },
    getScaleSelect(value) {
      if (!value) return 'linear';
      else return value;
    },
    valueSize(valueX, valueY, value, id) {
      if (!valueX || !valueY || !value) return;
      let idX = valueX.split(' : ')[0];
      let idY = valueY.split(' : ')[0];
      let idv = value.split(' : ')[0];
      if (idv === idX || idv === idY) return value;
      this.selectValue(id, null);
    },
    getListOptionSize(valueX, valueY) {
      let array = [];

      let idValueX = null;
      if (valueX) {
        idValueX = valueX.split(' : ')[0];
      }
      let idValueY = null;
      if (valueY) {
        idValueY = valueY.split(' : ')[0];
      }
      let options = this.pairs.map((p) => {
        if (idValueX) {
          if (p.split(' : ')[0] === idValueX) {
            let key = p.split(' : ')[1];
            let flag = false;
            for (let i = 0; i < array.length; i++) {
              if (array[i].label === key) flag = true;
            }
            if (!flag) array.push({ value: p, label: key });
          }
        }
        if (idValueY) {
          if (p.split(' : ')[0] === idValueY) {
            let key = p.split(' : ')[1];

            let flag = false;
            for (let i = 0; i < array.length; i++) {
              if (array[i].label === key) flag = true;
            }
            if (!flag) array.push({ value: p, label: key });
          }
        }
      });
      return array;
    },
    autoPickDatasource() {
      var self = this;
      /**
       * khi restore state (load project)
       * tự động chọn các datasource có trong pairSelected
       */
      let datasources = [];
      this.pairSelected.forEach((p) => {
        if (p.x) datasources.push(p.x.split(' : ')[0]);
        if (p.y) datasources.push(p.y.split(' : ')[0]);
        if (p.value) datasources.push(p.value.split(' : ')[0]);
      });
      let distinctDatasources = [...new Set(datasources)];

      distinctDatasources.forEach((d) => {
        if (!this.datasourceSelected.includes(d)) {
          this.datasourceSelected.push(d);
        }
      });
    },
    toggleDatasource(id) {
      let index = this.datasourceSelected.findIndex((d) => d === id);
      if (index === -1) {
        this.datasourceSelected.push(id);
      } else {
        this.datasourceSelected.splice(index, 1);
      }
    },
    addPair() {
      this.pairSelected.push({ id: uuidv4(), x: null, y: null, value: null, typeSelect: 'generateAll', zezoFill: false, symbol: null });
      // draw shape in pair
      this.$nextTick(() => {
        let pairElement = document.querySelector(`.shape-pair-selected-${this.pairSelected.length - 1}`);
        if (pairElement && this.symbolSelected) {
          let symbolSelected = this.markerSymbolDefault[(this.pairSelected.length - 1) % this.markerSymbolDefault.length];
          this.symbolSelected = [symbolSelected];
          this.getDataSymbolsSelected(this.symbolSelected);
          this.symbolSelectedChart(pairElement, this.dataSymbolsSelected);
          this.pairSelected[this.pairSelected.length - 1].symbol = this.symbols[symbolSelected];
        }
      });
    },
    deletePair(id) {
      this.pairSelected = this.pairSelected.filter((p) => p.id !== id);
    },

    selectX(id, value) {
      let index = this.pairSelected.findIndex((p) => p.id === id);
      let oldPair = this.pairSelected[index];
      this.pairSelected.splice(index, 1, { ...oldPair, x: value });
      this.$nextTick(() => {
        this.$refs.button.focus(); // focus lại vào button nếu không dropdown sẽ bị đóng
      });
      this.changeTitle()
    },
    selectY(id, value) {
      let index = this.pairSelected.findIndex((p) => p.id === id);
      let oldPair = this.pairSelected[index];
      this.pairSelected.splice(index, 1, { ...oldPair, y: value });
      this.$nextTick(() => {
        this.$refs.button.focus(); // focus lại vào button nếu không dropdown sẽ bị đóng
      });
      this.changeTitle()
    },
    selectValue(id, value) {
      let index = this.pairSelected.findIndex((p) => p.id === id);
      let oldPair = this.pairSelected[index];
      this.pairSelected.splice(index, 1, { ...oldPair, value: value });
      this.$nextTick(() => {
        this.$refs.button.focus(); // focus lại vào button nếu không dropdown sẽ bị đóng
      });
      this.changeTitle()
    },
    selectType(id, value) {
      let index = this.pairSelected.findIndex((p) => p.id === id);
      let oldPair = this.pairSelected[index];
      this.pairSelected.splice(index, 1, { ...oldPair, typeSelect: value });
      this.$nextTick(() => {
        this.$refs.button.focus(); // focus lại vào button nếu không dropdown sẽ bị đóng
      });
    },
    selectModeFill(id, indexElement) {
      let valueEle = document.querySelector('#checkbox-fill-' + indexElement).checked;
      let index = this.pairSelected.findIndex((p) => p.id === id);
      let oldPair = this.pairSelected[index];
      this.pairSelected.splice(index, 1, { ...oldPair, zezoFill: valueEle });
      this.$nextTick(() => {
        this.$refs.button.focus(); // focus lại vào button nếu không dropdown sẽ bị đóng
      });
    },
    itemCount(datasourceId) {
      let pairs = [];

      this.pairSelected.forEach((p) => {
        if (p.x) {
          let [dX, pX] = p.x.split(' : ');
          if (dX !== datasourceId) return;
          pairs.push(pX);
        }

        if (p.y) {
          let [dY, pY] = p.y.split(' : ');
          if (dY !== datasourceId) return;
          pairs.push(pY);
        }

        if (p.value) {
          let [dV, pV] = p.value.split(' : ');
          if (dV !== datasourceId) return;
          pairs.push(pV);
        }
        if (p.typeSelect) {
          let [dT, pT] = p.typeSelect.split(' : ');
          if (dT !== datasourceId) return;
          pairs.push(pT);
        }
        if (p.zezoFill) {
          let [dS, pS] = p.zezoFill.split(' : ');
          if (dS !== datasourceId) return;
          pairs.push(pS);
        }
      });

      return [...new Set(pairs)].length;
    },
    itemTotal(datasourceId) {
      if (!this.$db[datasourceId]) return 0;
      return Object.keys(this.$db[datasourceId].columns).length;
    },
    itemVariant(datasourceId) {
      let count = this.itemCount(datasourceId);
      let total = this.itemTotal(datasourceId);

      if (count === 0) return 'light-secondary';
      if (count === total) return 'light-success';
      return 'light-primary';
    },
    clickShowChartShape(index) {
      if (this.symbolSelected) {
        this.pairIndexSelected = index;
        this.showChartShape = true;
        document.querySelector(`.shape-pair-selected-${index}`).parentElement.appendChild(this.$refs.shapePairChart);
        this.symbolSelected = [this.symbols.indexOf(this.pairSelected[index].symbol)];
        this.mergeSymbolsSelectedInDataSymbols(this.symbolSelected);
        this.updateSymbolChart(this.$refs.shapePairChart, this.dataSymbols);
      }
    },
    getSymbols() {
      var isOpen = (s) => s.includes('-open');
      var isDot = (s) => s.includes('-dot');
      var isBase = (s) => !isOpen(s) && !isDot(s);
      var symbolList = Plotly.PlotSchema.get().traces.scatter.attributes.marker.symbol.values.filter((s) => typeof s === 'string');
      this.symbols = symbolList.filter(isBase);
      this.symbolSelected = [...this.symbolSelected.map((sb) => this.symbols.indexOf(sb))];
    },
    getDataSymbols(symbols) {
      var rowLen = this.rowLenSymbols;
      let xTrace = 0;
      let yTrace = -10;
      this.dataSymbols = [];
      for (let i = 0; i < symbols.length; i++) {
        // var s = symbols[i] + opt
        xTrace = i % rowLen;
        if (xTrace == 0) {
          yTrace += 10;
        }
        this.dataSymbols.push({
          x: [xTrace],
          y: [yTrace],
          mode: 'markers',
          type: 'scatter',
          marker: {
            color: DEFAULT_COLOR_HIDEN_SYMBOL,
            size: 16,
            symbol: symbols[i],
            line: {
              color: DEFAULT_COLOR_HIDEN_SYMBOL,
              width: 1,
            },
          },
          opacity: DEFAULT_OPACITY_HIDEN_SYMBOL,
          showlegend: false,
          hovertemplate: `${symbols[i]}<extra></extra>`,
        });
      }
    },
    mergeSymbolsSelectedInDataSymbols(symbolSelected) {
      this.getDataSymbols(this.symbols);
      if (symbolSelected.length > 0 && this.symbols.length > 0) {
        this.dataSymbols[symbolSelected[0]].opacity = 1;
        this.dataSymbols[symbolSelected[0]].marker.color = DEFAULT_COLOR_SHOW_SYMBOL;
      }
    },
    getDataSymbolsSelected(symbolsSected) {
      var rowLen = this.rowLenSymbols;
      let xTrace = 0;
      let yTrace = -1;
      this.dataSymbolsSelected = [];
      for (let i = 0; i < symbolsSected.length; i++) {
        // var s = symbols[i] + opt
        xTrace = i % rowLen;
        if (xTrace == 0) {
          yTrace += 1;
        }
        let symbol = this.symbols[symbolsSected[i]];
        this.dataSymbolsSelected.push({
          x: [xTrace],
          y: [yTrace],
          mode: 'markers',
          type: 'scatter',
          marker: {
            color: DEFAULT_COLOR_SHOW_SYMBOL,
            size: 16,
            symbol,
          },
          opacity: 1,
          hovertemplate: ``,
          hoverinfo: 'skip',
        });
      }
    },
    symbolChart(chart, data) {
      let layout = {
        width: 300,
        height: 800,
      };
      let _layout = _.merge(LAYOUT, layout);
      if (chart[0]) {
        chart[0] = '';
      }
      Plotly.newPlot(chart, data, _layout, CONFIG);
    },
    updateSymbolChart(chart, data) {
      let layout = {
        width: 300,
        height: 800,
      };
      let _layout = _.merge(LAYOUT, layout);
      if (chart[0]) {
        chart[0] = '';
      }
      Plotly.react(chart, data, _layout, CONFIG);
    },
    resetSymbolChart(chart, data, markerSymbol) {
      data.forEach((trace, id) => {
        if (markerSymbol.indexOf(id) > -1) {
          data[id].opacity = 1;
          data[id].marker.color = DEFAULT_COLOR_SHOW_SYMBOL;
        } else {
          data[id].opacity = DEFAULT_OPACITY_HIDEN_SYMBOL;
          data[id].marker.color = DEFAULT_COLOR_HIDEN_SYMBOL;
        }
      });
      let layout = {
        width: 300,
        height: 800,
      };
      let _layout = _.merge(LAYOUT, layout);
      Plotly.redraw(chart, data, _layout, CONFIG);
    },
    symbolSelectedChart(chart, data) {
      let layout = {
        width: 40,
        height: 40,
      };
      let _layout = _.merge(LAYOUT, layout);
      if (chart[0]) {
        chart[0] = '';
      }
      Plotly.newPlot(chart, data, _layout, CONFIG);
    },
    clickOutsideDiv(div) {
      const option_group = div;
      document.addEventListener('mousedown', (event) => {
        if (!option_group.contains(event.target)) {
          this.showChartShape = null;
        }
      });
    },
    clButton() {
      setTimeout(() => {
        this.moveCountRadioGroup++;
      }, 400);
    },
    changeDatasourceLiveMode(datasource) {
      datasource.live = !datasource.live;
      this.$store.commit('datasource/UPDATE_DATASOURCE', datasource);
    },
  },
};
</script>
<style lang="scss">
.scatter-select-container .show-hide-option {
  transform: rotate(90deg) !important;
  transition: 0.3s;
}
.scatter-select-container .show-hide-option.open {
  transform: rotate(270deg) !important;
}
.scatter-select-container .select-tilte,
.scatter-select-container .collapse-icon.layer-collapse.collapse-default {
  width: 100%;
}
.option-type-layout svg path {
  stroke: rgb(128, 128, 128) !important;
  stroke-width: 2 !important;
  fill: rgb(128, 128, 128) !important;
}

.shape-pair-chart {
  g {
    cursor: pointer !important;
  }

  .plot-container .main-svg {
    top: -25px !important;
  }
}
</style>
<style scoped lang="scss">
.scatter-select-container .layer-collapse .layer-collapse-item .card-header:after {
  transform: rotate(0deg) !important;
  right: 0;
}
.datasource-select-container {
  padding: 0;
  position: relative;
  width: 100%;
  border: none;
  outline: none;
  cursor: default !important;
  background-color: rgba(57, 123, 255, 0.12) !important;
  border-radius: 0.357rem;
  color: #5e5873;
}
.datasource-select-container:disabled .datasource-pointer {
  cursor: not-allowed !important;
}

.dark-layout .datasource-select-container {
  color: #d0d2d6;
}

.datasource-select-container .datasource-pointer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.datasource-select-container .datasource-title {
  text-align: left;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}

.datasource-select-container .datasource-description {
  color: #397bff;
}

.datasource-select-container .datasource-select {
  position: absolute;
  left: 0;
  top: 100%;
  transform: scale(0);
  opacity: 0;
  transform-origin: 150px 0;
  z-index: 2;
  border-radius: 7px;
  box-shadow: rgb(0 0 0 / 25%) 0px 25px 50px -12px;
  padding: 10px;
  width: 832px;
  transition: transform 0.4s, opacity 0.4s;
  margin-top: 10px;
  backdrop-filter: blur(2px);
  background-color: rgba(57, 123, 255, 0.12) !important;
}

/* .dark-layout .datasource-select-container .datasource-select {
  background-color: #161d31 !important;
} */

.datasource-select-container:focus .datasource-select {
  transform: scale(1);
  opacity: 1;
}
.datasource-select-container:focus-within .datasource-select {
  transform: scale(1);
  opacity: 1;
}

.datasource-select-container:focus .datasource-select:hover {
  opacity: 1;
}
.datasource-select-container:focus-within .datasource-select:hover {
  opacity: 1;
}

.datasource-select-container table {
  width: 100%;
  border-radius: 7px;
  overflow: hidden;
}

table {
  background-color: #fff;
}

.datasource-select-container th {
  padding: 10px;
  border: none;
  text-align: left;
  text-transform: uppercase;
}

.datasource-select-container td {
  padding: 0;
  border: none;
  text-align: left;
  max-width: 200px;
  vertical-align: top;
  border-right: 2px solid #f3f2f7 !important;
}

.dark-layout .datasource-select-container td {
  border-right: 2px solid #343d55 !important;
}

.datasource-select-container td:last-child {
  border-right: none !important;
}

.datasource-select-container .datasource-option {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.datasource-select-container .datasource-option:hover {
  background-color: #eeedfd;
}

.datasource-select-container .datasource-option.active {
  background-color: #d8dffd;
}

.dark-layout .datasource-select-container .datasource-option:hover {
  background-color: #31375a;
}

.dark-layout .datasource-select-container .datasource-option.active {
  background-color: #323f6e;
}

.datasource-select-container .datasource-option .datasource-content {
  flex-grow: 1;
  min-width: 0;
}

.datasource-select-container .datasource-option .datasource-content .datasource-name {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.datasource-select-container .scatter-option {
  padding: 10px;
  display: flex;
  align-items: center;
}
.radio-option-scatter {
  transition: 0.3s;
  overflow: hidden;
  height: 0px;
  padding: 0px 10px !important;
}
.radio-option-scatter.show-option {
  height: 40px !important;
  margin: 5px 10px 10px 10px !important;
  padding: 0px !important;
}

.datasource-select-container .scatter-option-select {
  margin-right: 5px;
  flex-grow: 1;
  flex-basis: 0;
}

.datasource-select-container .count-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.datasource-select-container .custom-scrollbar {
  min-height: 52px;
  max-height: 500px;
  overflow-y: auto;
}
.shape-pair {
  width: fit-content;
  max-height: 100%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  &-selected {
    border-radius: 5px;
    border: 1px solid rgba(115, 103, 240, 0.24);
    height: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  &-chart {
    border: 1px solid rgba(115, 103, 240, 0.24);
    border-radius: 5px;
    height: 160px;
    position: absolute;
    z-index: 2;
    overflow: scroll !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
  }
}
</style>
