<template>
  <b-card no-body class="scatter-chart-card">
    <b-card-body>
      <div class="scatter-chart" id="scatter-plotly-chart" ref="chart" :style="{ visibility: showChart ? 'visible' : 'hidden', opacity: showChart ? 1 : 0 }"></div>
      <div class="scatter-chart empty-chart" :style="{ visibility: showChart ? 'hidden' : 'visible' }">
        <empty-chart :description="$t('tips_scatter')" />
      </div>
      <chart-year-text ref="refChartYearText" v-if="showYear && showChart && $parent.yearText" :text="$parent.yearText"></chart-year-text>
    </b-card-body>
  </b-card>
</template>

<script>
const _ = require('lodash')
import Plotly from 'plotly.js-dist'
import DownloadMixin from '@/mixins/DownloadMixin'
import EmptyChart from '../../common/EmptyChart.vue'
import ChartYearText from '../../common/ChartYearText.vue'
import { locale } from '@/scripts/plotly/locale.js'
import { CustomLayout } from '@/mixins/ThemeMixin.js'
const LAYOUT = {
  hovermode: 'closest',
  yaxis: { automargin: true, autorange: false },
  xaxis: { automargin: true, autorange: false },
}
const CONFIG = {
  displaylogo: false,
  responsive: true,
  editable: true,
  modeBarButtonsToRemove: ['toImage', 'select2d', 'lasso2d'],
}

export default {
  props: ['showYear', 'nameComponent'],
  mixins: [DownloadMixin, CustomLayout],
  components: { EmptyChart, ChartYearText, locale },
  data() {
    return {
      showChart: false,
      funcEventPlotlyRelayout: null,
    }
  },
  mounted() {
    'undefined' == typeof Plotly ? ((window.PlotlyLocales = window.PlotlyLocales || []), window.PlotlyLocales.push(locale)) : Plotly.register(locale)
    // Listen to div resize => chart resize
    this.$nextTick(() => {
      new ResizeObserver(() => {
        if (this.$route.name == 'scatter' && this.$refs.chart && this.$refs.chart.style && this.$refs.chart.style.visibility && this.$refs.chart.style.visibility !== 'hidden') {
          Plotly.Plots.resize(this.$refs.chart)
        }
      }).observe(this.$refs.chart)
    })
  },
  computed: {
    editable() {
      return this.$store.state.tabs[this.nameComponent].editable
    },
    chartFontFamily() {
      return this.$store.state.tabs[this.nameComponent].chartFontFamily
    },
    chartTextSize() {
      return this.$store.state.tabs[this.nameComponent].chartTextSize
    },
    chartTextColor() {
      return this.$store.state.tabs[this.nameComponent].chartTextColor
    },
    chartFont() {
      let font = {}
      try {
        if (this.chartFontFamily) {
          font.family = this.chartFontFamily
        }
        if (this.chartTextSize) {
          font.size = this.chartTextSize
        }
        if (this.chartTextColor) {
          font.color = this.chartTextColor
        }
      } catch {}
      return font
    },
    chartLayout() {
      return this.$store.state.tabs.scatter.chartLayout
    },
  },
  watch: {
    chartFont: {
      deep: true,
      handler() {
        if (this.chartFont) {
          this.updateChartLayout()
        }
      },
    },
    $route: {
      deep: true,
      handler() {
        try {
          if (this.$route.name == 'scatter') {
            this.eventPlotlyLayout()
          }
        } catch {}
      },
    },
  },
  methods: {
    eventPlotlyLayout() {
      const self = this
      try {
        if (self.funcEventPlotlyRelayout) {
          clearTimeout(self.funcEventPlotlyRelayout)
        }
        self.funcEventPlotlyRelayout = setTimeout(() => {
          let plotlyElement = document.getElementById('scatter-plotly-chart')
          if (plotlyElement) {
            plotlyElement.on('plotly_relayout', (data) => {
              try {
                let layoutOrigin = plotlyElement.layout
                let layoutTemp = {
                  xaxis: {},
                  yaxis: {},
                }
                if (layoutOrigin.title) {
                  layoutTemp.title = layoutOrigin.title
                }
                if (layoutOrigin.xaxis && layoutOrigin.xaxis.title) {
                  layoutTemp.xaxis.title = layoutOrigin.xaxis.title
                }
                if (layoutOrigin.yaxis && layoutOrigin.yaxis.title) {
                  layoutTemp.yaxis.title = layoutOrigin.yaxis.title
                }
                if (self.$route && self.$route.name == 'scatter') {
                  self.$store.commit(`tabs/SET_SCATTER`, { chartLayout: layoutTemp })
                }
              } catch {}
            })
          }
        }, 1000)
      } catch {}
    },
    createChart(data, layout, locale) {
      let _config = CONFIG
      _config.locale = locale
      this.showChart = Boolean(data.length)
      let layoutTemp = {}
      try {
        if (this.chartFont) {
          layoutTemp = _.merge(layoutTemp, { font: this.chartFont })
        }
        if (this.chartLayout) {
          layoutTemp = _.merge(layoutTemp, this.chartLayout)
        }
      } catch {}
      let _layout = _.merge({}, this.themeLayout, LAYOUT, layout, layoutTemp)
      if (this.$refs.chart && this.$refs.chart.layout) {
        if (this.$refs.chart.layout.legend) {
          _layout.legend = this.$refs.chart.layout.legend
        }
        if (this.$refs.chart.layout.title) {
          _layout.title = this.$refs.chart.layout.title
        }
        if (this.$refs.chart.layout.xaxis.title) {
          _layout.xaxis.title = this.$refs.chart.layout.xaxis.title
        }
        if (this.$refs.chart.layout.yaxis.title) {
          _layout.yaxis.title = this.$refs.chart.layout.yaxis.title
        }
      }
      _config = _.merge(_config, { editable: this.editable })
      Plotly.newPlot(this.$refs.chart, data, _layout, _config)
      this.eventPlotlyLayout()
    },
    updateChart(data, layout, locale) {
      let _config = CONFIG
      _config.locale = locale
      this.showChart = Boolean(data.length)
      let layoutTemp = {}
      try {
        if (this.chartFont) {
          layoutTemp = _.merge(layoutTemp, { font: this.chartFont })
        }
        if (this.chartLayout) {
          layoutTemp = _.merge(layoutTemp, this.chartLayout)
        }
      } catch {}
      let _layout = _.merge({}, this.$refs.chart.layout, this.themeLayout, LAYOUT, layout, layoutTemp)
      Plotly.react(this.$refs.chart, data, _layout, _config)
      if (layout.xaxis) this.$refs.chart._fullLayout.xaxis._rangeInitial = layout.xaxis.range
      if (layout.yaxis) this.$refs.chart._fullLayout.yaxis._rangeInitial = layout.yaxis.range
    },
    animateChart(data, speed) {
      if (this.showChart) {
        Plotly.animate(
          this.$refs.chart,
          { data },
          {
            transition: {
              duration: 1000 / speed,
              easing: 'linear',
            },
            frame: {
              duration: 1000 / speed,
              redraw: false,
            },
          }
        )
        Plotly.restyle(this.$refs.chart, { param: '' })
      }
    },
    updateChartLayout() {
      // setTimeout(() => {
      try {
        let layout = this.$refs.chart.layout
        let layoutTemp = {}
        try {
          if (this.chartFont) {
            layoutTemp = _.merge(layoutTemp, { font: this.chartFont })
          }
          if (this.chartLayout) {
            layoutTemp = _.merge(layoutTemp, this.chartLayout)
          }
        } catch {}
        layout = _.merge(layout, layoutTemp)
        Plotly.relayout(this.$refs.chart, layout)
        // Plotly.react(this.$refs.chart, this.$refs.chart.data, layout);
      } catch {}
      // }, 300);
    },
  },
}
</script>

<style scoped>
.scatter-chart-card .card-body {
  padding-top: 0;
}
.scatter-chart.empty-chart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.scatter-chart {
  height: calc(100vh - 30px - 90.3px - 90px - 2rem - 36px);
}
</style>
