<template>
  <div>
    <b-card class="scatter-card custom-card">
      <b-row class="flex-nowrap d-flex align-items-center">
        <b-col cols="auto">
          <b-form-group class="mb-0" style="min-width: 300px">
            <ScatterSelect v-model="datasourceSelected" :optionSelect="true" :nameComponent="$options.name" />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <option-chart :label="$t('option_chart')" :showScale="true" :options="[]" :typeList="[]" :nameComponent="$options.name" />
        </b-col>
        <b-col cols="auto" class="ml-auto d-flex align-items-center">
          <SummaryData :chartData="chartData" />
          <Downloader :downloadImage="downloadImage" :downloadHtml="downloadHtml" :downloadCsv="downloadCsv" />
        </b-col>
      </b-row>
    </b-card>
    <ScatterChart ref="chart" :showYear="showYear" :nameComponent="$options.name" />
    <Timeplayer ref="timeplayer" v-model="date" :liveMode="liveMode" :speedMax="12" :speedValue="speed" @speedInput="speed = $event" :moveToEnd="moveToEnd" />
  </div>
</template>

<script>
const _ = require('lodash');
import ScatterSelect from '../../common/DatasourceSelect/ScatterSelect.vue';
import Downloader from '../../common/Downloader.vue';
import RadioGroup from '../../common/RadioGroup.vue';
import OptionChart from '../../common/OptionChartPlotly.vue';
import SummaryData from '../../common/SummaryData.vue';
import Timeplayer from '../../common/Timeplayer.vue';
import PausePlayerMixin from '@/mixins/PausePlayerMixin';
import ScaleMixin from '@/mixins/ScaleMixin';
import ChartYearMixin from '@/mixins/ChartYearMixin';
import ScatterChart from './ScatterChartComponent.vue';
import i18n from '@/libs/i18n';
import { scaleValueLog10, scaleValueLog10Plus, scaleValueNormalize, scaleValueStandardize } from '@/utilities/NumberUtility.js';
import { isAllEmpty } from '@/utilities/ArrayUtility.js';
import { SelectedGroupMixin } from '@/mixins/GroupItemsMixin';
import { OPTION_PLOTLY_PALETTE } from '@/constants/colors';
import { replaceNaWithZero } from '@/utilities/NumberUtility.js';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';

const MIN_SIZE = 5;
const MAX_SIZE = 30;

export default {
  name: 'scatter',
  components: { ScatterSelect, ScatterChart, Timeplayer, SummaryData, RadioGroup, Downloader, OptionChart, ToastificationContent },
  mixins: [PausePlayerMixin, ScaleMixin, ChartYearMixin, SelectedGroupMixin],
  async mounted() {
    let { data, layout } = await this.getLayoutAndData();
    this.$refs.chart.createChart(data, layout, i18n.locale);
  },
  data() {
    return {
      speed: 6,
      datasourceExtended: [],
      chartData: [], // Value array use for Summary
      moveToEnd: 0,
    };
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources;
    },
    datasourceSelected: {
      get() {
        return this.$store.state.tabs[this.$options.name].datasourceSelected;
      },
      set(datasourceSelected) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { datasourceSelected });
      },
    },
    showYear() {
      return this.$store.state.tabs[this.$options.name].year;
    },
    showLegend() {
      return this.$store.state.tabs[this.$options.name].legend;
    },
    sizeWidth() {
      return this.$store.state.tabs[this.$options.name].sizeWidth;
    },
    outlineGroup() {
      return this.$store.state.tabs[this.$options.name].outlineGroup;
    },
    date: {
      get() {
        return this.$store.state.tabs[this.$options.name].date;
      },
      set(date) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { date });
      },
    },
    markerColor() {
      let colors = this.$store.state.tabs[this.$options.name].colors;
      colors = colors && colors.length > 0 ? colors : OPTION_PLOTLY_PALETTE.D3;
      return colors;
    },
    markerSymbol() {
      return this.$store.state.tabs[this.$options.name].symbolSelected;
    },
    tempProject() {
      return this.$store.state.ecoplot.tempProject;
    },
    locale() {
      return i18n.locale;
    },
    editable() {
      return this.$store.state.tabs[this.$options.name].editable;
    },
    liveMode() {
      try {
        let check = false;
        if (!this.datasources || !this.datasourceSelected) return false;
        let arryIdDatasources = [];
        this.datasources.map((data) => {
          arryIdDatasources.push(data.id);
        });
        arryIdDatasources = _.unionBy(arryIdDatasources);
        this.datasourceSelected.map((data) => {
          let splitX = data.x ? data.x.split(' : ') : null;
          const idX = splitX ? splitX[0] : null;
          let splitY = data.y ? data.y.split(' : ') : null;
          const idY = splitY ? splitY[0] : null;
          if (idX && idY && data.value) {
            let indexDataX = arryIdDatasources.indexOf(idX);
            let indexDataY = arryIdDatasources.indexOf(idY);
            if ((indexDataX > -1 && this.datasources[indexDataX].live) || (indexDataY > -1 && this.datasources[indexDataY].live)) {
              check = true;
              return check;
            }
          }
        });
        return check;
      } catch {}
      return false;
    },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode;
    },
  },
  watch: {
    async editable() {
      let { data, layout } = await this.getLayoutAndData();
      this.$refs.chart.createChart(data, layout, i18n.locale);
    },
    selectedGroup: {
      deep: true,
      async handler() {
        try {
          let { data, layout } = await this.getLayoutAndData();
          this.$refs.chart.updateChart(data, layout, i18n.locale);
        } catch {}
      },
    },
    async sizeWidth() {
      let { data, layout } = await this.getLayoutAndData();
      this.$refs.chart.updateChart(data, layout, i18n.locale);
    },
    async outlineGroup() {
      let { data, layout } = await this.getLayoutAndData();
      this.$refs.chart.updateChart(data, layout, i18n.locale);
    },
    async markerSymbol() {
      let { data, layout } = await this.getLayoutAndData();
      this.$refs.chart.updateChart(data, layout, i18n.locale);
    },
    async markerColor() {
      let { data, layout } = await this.getLayoutAndData();
      this.$refs.chart.updateChart(data, layout, i18n.locale);
    },
    async showLegend() {
      let { data, layout } = await this.getLayoutAndData();
      this.$refs.chart.updateChart(data, layout, i18n.locale);
    },
    datasourceSelected() {
      let datasourceExtended = [];
      this.datasourceSelected.forEach((datasourceSelected) => {
        if (!datasourceSelected.x || !datasourceSelected.y || !datasourceSelected.value) return;
        let [dX, iX] = datasourceSelected.x.split(' : ');
        let [dY, iY] = datasourceSelected.y.split(' : ');
        let [dV, iV] = datasourceSelected.value.split(' : ');
        if (!this.$db[dX] || !this.$db[dY] || !this.$db[dV]) return;
        if (!datasourceSelected.typeSelect) datasourceSelected.typeSelect = 'generateAll';
        if (datasourceSelected.typeSelect === 'generateAll') {
          let locationsX = Object.keys(this.$db[dX].columns)
            .filter((pair) => pair.endsWith(`*${iX}`))
            .map((pair) => pair.split('*')[0]);
          let locationsY = Object.keys(this.$db[dY].columns)
            .filter((pair) => pair.endsWith(`*${iY}`))
            .map((pair) => pair.split('*')[0]);
          let locationsV = Object.keys(this.$db[dV].columns)
            .filter((pair) => pair.endsWith(`*${iV}`))
            .map((pair) => pair.split('*')[0]);
          locationsX = locationsX.concat(locationsY).concat(locationsV);
          locationsY = locationsY.concat(locationsX).concat(locationsV);
          locationsV = locationsV.concat(locationsX).concat(locationsY);
          let locationsIntersect = _.intersection(locationsX, locationsY, locationsV);
          datasourceExtended.push({ dX, iX, dY, iY, dV, iV, locations: locationsIntersect, zezoFill: datasourceSelected.zezoFill, symbol: datasourceSelected.symbol });
        } else {
          if (dX === dY && dY === dV) {
            let locationsX = Object.keys(this.$db[dX].columns)
              .filter((pair) => pair.endsWith(`*${iX}`))
              .map((pair) => pair.split('*')[0]);
            let locationsY = Object.keys(this.$db[dY].columns)
              .filter((pair) => pair.endsWith(`*${iY}`))
              .map((pair) => pair.split('*')[0]);
            let locationsV = Object.keys(this.$db[dV].columns)
              .filter((pair) => pair.endsWith(`*${iV}`))
              .map((pair) => pair.split('*')[0]);
            let locationsIntersect = _.intersection(locationsX, locationsY, locationsV);
            datasourceExtended.push({ dX, iX, dY, iY, dV, iV, locations: locationsIntersect, zezoFill: datasourceSelected.zezoFill, symbol: datasourceSelected.symbol });
          } else {
            this.$toast({ component: ToastificationContent, props: { title: this.$t('warning'), icon: 'BellIcon', text: this.$i18n.t('no_data_match'), variant: 'warning ' } });
          }
        }
      });
      this.datasourceExtended = datasourceExtended;
    },
    async datasourceExtended(newValue, oldValue) {
      // Only update if value truly changed
      if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
        this.initFirstTime = true;
        this.moveToEnd++;
      }
    },
    async scale() {
      let { data, layout } = await this.getLayoutAndData();
      this.$refs.chart.updateChart(data, layout, i18n.locale);
    },
    async date() {
      if (this.initFirstTime) {
        let { data, layout } = await this.getLayoutAndData(true);
        this.$refs.chart.updateChart(data, layout, i18n.locale);
      } else {
        let { data, layout } = await this.getLayoutAndData();
        this.$refs.chart.animateChart(data, this.speed);
      }
    },
    async locale() {
      let { data, layout } = await this.getLayoutAndData();
      if (data.length > 0) {
        this.$refs.chart.updateChart(data, layout, i18n.locale);
      }
    },
    async countLiveMode() {
      let { data, layout } = await this.getLayoutAndData();
      if (data.length > 0) {
        this.$refs.chart.updateChart(data, layout, i18n.locale);
      }
    },
  },
  methods: {
    async getData() {
      let data = [];
      let vData = [];
      let traceCount = 0;
      let maxSize = MAX_SIZE;
      let minSize = MIN_SIZE;
      let oulineMarker = {};
      if (this.sizeWidth.default == true) {
        minSize = this.sizeWidth.range[0];
        maxSize = this.sizeWidth.range[1];
      }
      if (this.outlineGroup.default == true) {
        oulineMarker = {
          color: this.changeColorAlpha(this.outlineGroup.color, Number(this.outlineGroup.opacity)),
          width: this.outlineGroup.width,
        };
      }
      // min date and max date (range case and single case)
      const minDate = this.date.length === 2 ? this.date[0] : this.date.length === 1 ? this.date[0] : '';
      const maxDate = this.date.length === 2 ? this.date[1] : this.date.length === 1 ? this.date[0] : '';

      // Query by datasourceExtended
      for (const { dX, iX, dY, iY, dV, iV, locations, zezoFill, symbol } of this.datasourceExtended) {
        let arrayIdLocation = _.intersection([dX, dY, dV]);
        const metadata = this.$db[dX];
        let recordsX = [];
        let recordsY = [];
        let recordsV = [];
        const locationsItemsX = _.map(locations, (l) => l + '-' + iX);
        const locationsItemsY = _.map(locations, (l) => l + '-' + iY);
        const locationsItemsV = _.map(locations, (l) => l + '-' + iV);
        // }
        this.$store.commit('ecoplot/SET_STATUS_SCATTER_QUERY_DATA', false);
        // Build 'trace' for each location
        if (arrayIdLocation && arrayIdLocation.length !== 1) {
          let newlocationsItemsX = await this.checkLocations(locationsItemsX, dX, this.scale);
          let newlocationsItemsY = await this.checkLocations(locationsItemsY, dY, this.scale);
          let newlocationsItemsV = await this.checkLocations(locationsItemsV, dV, this.scale);
          recordsX = await this.selectRangeByLocationsItems(dX, newlocationsItemsX, minDate, maxDate, this.scale);
          recordsY = await this.selectRangeByLocationsItems(dY, newlocationsItemsY, minDate, maxDate, this.scale);
          recordsV = await this.selectRangeByLocationsItems(dV, newlocationsItemsV, minDate, maxDate, this.scale);
          if (zezoFill) {
            recordsX = this.scatterZezoFill(recordsX);
            recordsY = this.scatterZezoFill(recordsY);
            recordsV = this.scatterZezoFill(recordsV);
          }
          for (let i = 0; i < newlocationsItemsX.length; i++) {
            for (let j = 0; j < newlocationsItemsY.length; j++) {
              // Get 'trace' name
              const name = `${newlocationsItemsX[i].replace('-' + iX, '') + ':' + newlocationsItemsY[j].replace('-' + iY, '')} (${iX}, ${iY}, ${iV})`;
              const locationItemX = newlocationsItemsX[i];
              const locationItemY = newlocationsItemsY[j];
              const locationItemV = newlocationsItemsV[0];
              // Get 'trace' data
              const arrObjectsX = recordsX[locationItemX];
              const x = arrObjectsX ? arrObjectsX : [0];

              const arrObjectsY = recordsY[locationItemY];
              const y = arrObjectsY ? arrObjectsY : [0];
              const v = [];
              if (dV === dX) {
                v = x;
              } else if (dV === dY) {
                v = y;
              } else {
                v = [0];
              }
              vData = vData.concat(v);
              // Get 'trace' size & symbol
              const size = v.map((value) => ((value - this.vRange[0]) / (this.vRange[1] - this.vRange[0])) * (maxSize - minSize) + minSize);
              // const symbol = this.markerColor.length > 0 ? this.markerSymbol[Math.floor(traceCount / this.markerColor.length) % this.markerSymbol.length] : null;
              const color = this.markerColor[traceCount % this.markerColor.length];
              const visible = this.selectedGroup && Object.keys(this.selectedGroup).length !== 0 && !this.selectedGroup.items.includes(location) ? 'legendonly' : true;
              this.initFirstTime = false;

              if (isAllEmpty(x) || isAllEmpty(y) || isAllEmpty(v)) {
                data.push({ name, visible, mode: 'markers', x: x, y: y, text: v, hovertemplate: `NA`, marker: { color, size: 15, opacity: 0.2, symbol: symbol, line: oulineMarker ? oulineMarker : { color: '#80808000' } } });
              } else data.push({ name, visible, mode: 'markers', x: x, y: y, text: v, hovertemplate: `(x:%{x}, y:%{y}, v:%{text})`, marker: { color, size: size, opacity: 1, symbol: symbol, line: oulineMarker ? oulineMarker : { color: '#FFFFFF' } } });
              traceCount++;
            }
          }
        } else {
          const allowQueryData = metadata ? !(minDate > metadata.dates[metadata.dates.length - 1] || maxDate < metadata.dates[0]) : true;
          if (allowQueryData) {
            recordsX = await this.selectRangeByLocationsItems(dX, locationsItemsX, minDate, maxDate, this.scale);
            recordsY = await this.selectRangeByLocationsItems(dY, locationsItemsY, minDate, maxDate, this.scale);
            recordsV = await this.selectRangeByLocationsItems(dV, locationsItemsV, minDate, maxDate, this.scale);
            if (zezoFill) {
              recordsX = this.scatterZezoFill(recordsX);
              recordsY = this.scatterZezoFill(recordsY);
              recordsV = this.scatterZezoFill(recordsV);
            }
          }
          locations.forEach((location) => {
            // Get 'trace' name
            // const name = `${location} (${iX}, ${iY}, ${iV})`;
            const name = `${location}`;
            const locationItemX = location + '-' + iX;
            const locationItemY = location + '-' + iY;
            const locationItemV = location + '-' + iV;
            // Get 'trace' data
            const arrObjectsX = recordsX[locationItemX];
            const x = arrObjectsX ? arrObjectsX : [null];
            const arrObjectsY = recordsY[locationItemY];
            const y = arrObjectsY ? arrObjectsY : [null];
            const arrObjectsV = recordsV[locationItemV];
            const v = arrObjectsV ? arrObjectsV : [null];
            vData = vData.concat(v);
            const size = v.map((value) => ((value - this.vRange[0]) / (this.vRange[1] - this.vRange[0])) * (maxSize - minSize) + minSize);
            const color = this.markerColor[traceCount % this.markerColor.length];
            const visible = this.selectedGroup && Object.keys(this.selectedGroup).length !== 0 && !this.selectedGroup.items.includes(location) ? 'legendonly' : true;
            this.initFirstTime = false;
            if (isAllEmpty(x) || isAllEmpty(y) || isAllEmpty(v)) {
              data.push({ name, visible, mode: 'markers', x: x, y: y, text: v, hovertemplate: `NA`, marker: { color, size: 15, opacity: 0.2, symbol, line: oulineMarker ? oulineMarker : { color: '#80808000' } } });
            } else data.push({ name, visible, mode: 'markers', x: x, y: y, text: v, hovertemplate: `(x:%{x}, y:%{y}, v:%{text})`, marker: { color, size: size, opacity: 1, symbol: symbol, line: oulineMarker ? oulineMarker : { color: '#FFFFFF' } } });
            traceCount++;
          });
        }
      }
      this.chartData = vData;
      return data;
    },
    /** Calculate min-max range for layout */
    async getLayoutAndData() {
      let layout = null;
      let data;
      layout = {
        legend: { itemsizing: 'constant', itemclick: true, itemdoubleclick: true },
        showlegend: this.showLegend,
      };
      try {
        let minX = Infinity;
        let minY = Infinity;
        let minV = Infinity;
        let maxX = -Infinity;
        let maxY = -Infinity;
        let maxV = -Infinity;

        for (const { dX, iX, dY, iY, dV, iV, locations } of this.datasourceExtended) {
          const datasourceX = this.datasources.find((d) => d.id === dX);
          const datasourceY = this.datasources.find((d) => d.id === dY);
          const datasourceV = this.datasources.find((d) => d.id === dV);

          if (datasourceX.storage_mode === 'FILE') {
            minX = Math.min(minX, this.generateScale(this.$db[dX].items[iX].min, this.$db[dX].items[iX], this.scale));
            maxX = Math.max(maxX, this.generateScale(this.$db[dX].items[iX].max, this.$db[dX].items[iX], this.scale));
          } else {
            const locationsItemsX = locations.map((l) => l + '-' + iX);
            let recordsX = await this.selectAllByLocationsItems(dX, locationsItemsX, this.scale);
            let recordsXValue = Object.values(recordsX);
            //remove column date
            recordsXValue.shift();
            minX = Math.min(minX, _.min([].concat(...recordsXValue)));
            maxX = Math.max(maxX, _.max([].concat(...recordsXValue)));
          }
          if (datasourceY.storage_mode === 'FILE') {
            minY = Math.min(minY, this.generateScale(this.$db[dY].items[iY].min, this.$db[dY].items[iY], this.scale));
            maxY = Math.max(maxY, this.generateScale(this.$db[dY].items[iY].max, this.$db[dY].items[iY], this.scale));
          } else {
            const locationsItemsY = locations.map((l) => l + '-' + iY);
            let recordsY = await this.selectAllByLocationsItems(dY, locationsItemsY, this.scale);
            let recordsYValue = Object.values(recordsY);
            //remove column date
            recordsYValue.shift();
            minY = Math.min(minY, _.min([].concat(...recordsYValue)));
            maxY = Math.max(maxY, _.max([].concat(...recordsYValue)));
          }
          if (datasourceV.storage_mode === 'FILE') {
            minV = Math.min(minV, this.generateScale(this.$db[dV].items[iV].min, this.$db[dV].items[iV], this.scale));
            maxV = Math.max(maxV, this.generateScale(this.$db[dV].items[iV].max, this.$db[dV].items[iV], this.scale));
          } else {
            const locationsItemsV = locations.map((l) => l + '-' + iV);
            let recordsV = await this.selectAllByLocationsItems(dV, locationsItemsV, this.scale);
            let recordsVValue = Object.values(recordsV);
            //remove column date
            recordsVValue.shift();
            minV = Math.min(minV, _.min([].concat(...recordsVValue)));
            maxV = Math.max(maxV, _.max([].concat(...recordsVValue)));
          }
        }
        // minX have value means that all other variables also have value
        if (minX !== Infinity) {
          let marginX = (maxX - minX) / 20;
          let marginY = (maxY - minY) / 20;
          layout.xaxis = { range: [minX - marginX, maxX + marginX] };
          layout.yaxis = { range: [minY - marginY, maxY + marginY] };
          this.vRange = [minV, maxV];
        } else {
          this.vRange = [];
        }
        data = await this.getData();
      } catch {}
      return { data, layout };
    },
    async getDataCSV() {
      let dataCSV = '';
      try {
        let id = 'ID';
        let item = 'ITEM';
        let unit = 'UNIT';
        let dataLines = [];
        let lineCSV = '';
        let recordsDate = [];

        // min date and max date (range case and single case)
        const minDate = this.date.length === 2 ? this.date[0] : this.date.length === 1 ? '0000-00-00 00:00:00' : '';
        const maxDate = this.date.length === 2 ? this.date[1] : this.date.length === 1 ? this.date[0] : '';

        // map all date
        for (const { dX, iX, dY, iY, dV, iV, locations } of this.datasourceExtended) {
          const locationsItemsX = _.map(locations, (l) => l + '-' + iX);
          const recordsX = await this.selectRangeByLocationsItems(dX, locationsItemsX, minDate, maxDate, this.scale);
          if (recordsX.dates && recordsX.dates != null) {
            recordsDate = recordsDate[0] && new Date(recordsDate[0]) > new Date(recordsX.dates[0]) ? [...new Set([...recordsX.dates, ...recordsDate])] : [...new Set([...recordsDate, ...recordsX.dates])];
          }
        }
        // Query by datasourceExtended
        for (const { dX, iX, dY, iY, dV, iV, locations } of this.datasourceExtended) {
          const locationsItemsX = _.map(locations, (l) => l + '-' + iX);
          const recordsX = await this.selectRangeByLocationsItems(dX, locationsItemsX, minDate, maxDate, this.scale);
          const locationsItemsY = _.map(locations, (l) => l + '-' + iY);
          const recordsY = await this.selectRangeByLocationsItems(dY, locationsItemsY, minDate, maxDate, this.scale);
          const locationsItemsV = _.map(locations, (l) => l + '-' + iV);
          const recordsV = await this.selectRangeByLocationsItems(dV, locationsItemsV, minDate, maxDate, this.scale);
          let unitTempX = '';
          try {
            unitTempX = `${this.$db[dX].items[iX].unit}`;
          } catch {}
          let unitTempY = '';
          try {
            unitTempY = `${this.$db[dY].items[iY].unit}`;
          } catch {}
          let unitTempV = '';
          try {
            unitTempV = `${this.$db[dV].items[iV].unit}`;
          } catch {}

          locations.forEach((location) => {
            // const name = `${location} (${iX}, ${iY}, ${iV})`;
            const name = `${location}`;
            const locationItemX = location + '-' + iX;
            const locationItemY = location + '-' + iY;
            const locationItemV = location + '-' + iV;
            id += ',' + location + ',' + location + ',' + location;
            item += ',' + iX + ',' + iY + ',' + iV;
            unit += `,${unitTempX},${unitTempY},${unitTempV}`;

            // Get 'trace' data
            const arrObjectsX = recordsX[locationItemX];
            const x = arrObjectsX ? arrObjectsX : [];
            const arrObjectsY = recordsY[locationItemY];
            const y = arrObjectsY ? arrObjectsY : [];
            const arrObjectsV = recordsV[locationItemV];
            const v = arrObjectsV ? arrObjectsV : [];
            recordsDate.forEach((date, index) => {
              dataLines[index] = dataLines[index] && dataLines[index] != '' ? dataLines[index] : date;
              let ids = recordsX.dates.indexOf(date);
              if (ids != -1) {
                dataLines[index] += ',' + x[ids] + ',' + y[ids] + ',' + v[ids];
              } else {
                dataLines[index] += ',,,';
              }
            });
          });
        }
        dataLines.forEach((line) => {
          lineCSV += line + '\r\n';
        });
        if (lineCSV != '') {
          dataCSV += id + '\r\n' + item + '\r\n' + unit + '\r\n' + lineCSV;
        }
      } catch {}
      return dataCSV;
    },
    scatterZezoFill(data) {
      for (const item in data) {
        data[item] = replaceNaWithZero(data[item]);
      }
      return data;
    },
    downloadHtml() {
      this.$refs.chart.asHtml(this.tempProject.name + '_Scatter');
    },
    downloadImage() {
      this.$refs.chart.asImage(this.tempProject.name + '_Scatter');
    },
    async downloadCsv() {
      let data = await this.getDataCSV();
      this.$refs.chart.asCSV(this.tempProject.name + '_Scatter', data);
    },
    /** Generate scale for a datasource */
    generateScale(value, item, scale) {
      if (scale === 'log10') {
        value = scaleValueLog10(value);
      } else if (scale === 'log10+') {
        value = scaleValueLog10Plus(value);
      } else if (scale === 'normalize') {
        value = scaleValueNormalize(value, item.min, item.max);
      } else if (scale === 'standardize') {
        value = scaleValueStandardize(value, item.mean, item.std);
      } else if (scale === 'live') {
        value = value;
      }

      return value;
    },
    changeColorAlpha(color, opacity) {
      if (color.length > 7) color = color.substring(0, color.length - 2);
      const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
      let opacityHex = _opacity.toString(16).toUpperCase();
      // opacities near 0 need a trailing 0
      if (opacityHex.length == 1) opacityHex = '0' + opacityHex;
      return color + opacityHex;
    },
  },
};
</script>
